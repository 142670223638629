import React from "react"
import imgProfile from "../images/vatbot-profile.png"

import Layout from "../components/layout"
import SEO from "../components/seo"
import VatCalculator from "../components/vat-calculator";
import {Helmet} from "react-helmet";

export default class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
            integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
            crossorigin="anonymous"
          />
        </Helmet>
        <SEO title="VAT Calculator" />
        <div style={{ textAlign: 'center' }}>
          <img style={{ marginTop: '50px', maxWidth: '300px' }} src={imgProfile} alt='VAT Calculator' />
          <h1>VAT Calculator</h1>
          <p>Calculate VAT with VatBot</p>
        </div>

        <VatCalculator />
      </Layout>
    )
  }
}