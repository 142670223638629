import React from "react"
import "./vat-calculator.css"

export default class VatCalculator extends React.Component {
  state = {
    value: '',
    vatRate: 20
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  displayAddVat = () => {
    return this.parseNumber((this.state.value * (1 + (this.state.vatRate / 100))) - this.state.value);
  }

  displayAddNet = () => {
    return this.parseNumber(this.state.value || 0);
  }

  displayAddGross = () => {
    return this.parseNumber(Number(this.displayAddNet()) + Number(this.displayAddVat()));
  }

  displayRemoveVat = () => {
    return this.parseNumber(Number(this.displayRemoveGross()) - Number(this.displayRemoveNet()));
  }

  displayRemoveNet = () => {
    return this.parseNumber(this.state.value / (1 + (this.state.vatRate / 100)));
  }

  displayRemoveGross = () => {
    return this.parseNumber(this.state.value || 0);
  }

  parseNumber = number => {
    return Number(number).toFixed(2);
  }

  render() {
    return (
      <div className='calculator'>
        <form className='container'>

        <div className='form-row align-items-center'>
          <div className="form-group col-12 col-sm-6">
            <label htmlFor="value">Value</label>
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">£</div>
              </div>
              <input
                autoFocus
                id="value"
                type="text"
                name="value"
                value={this.state.value}
                onChange={this.handleInputChange}
                placeholder="0"
                className='number form-control'

              />
            </div>
          </div>

          <div className="form-group col-12 col-sm-6">
            <label htmlFor="vatRate">VAT rate</label>
            <div className="input-group">
              <input
                id="vatRate"
                type="text"
                name="vatRate"
                value={this.state.vatRate}
                onChange={this.handleInputChange}
                className='number form-control'
              />
              <div className="input-group-append">
                <div className="input-group-text">%</div>
              </div>
            </div>
          </div>
        </div>

        </form>

        <table>
          <thead>
            <tr>
              <th>Total</th>
              <th>Add</th>
              <th>Subtract</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Net</td>
              <td><span className='number'>£{this.displayAddNet()}</span></td>
              <td><span className='number'>£{this.displayRemoveNet()}</span></td>
            </tr>
            <tr>
              <td>VAT</td>
              <td><span className='number'>£{this.displayAddVat()}</span></td>
              <td><span className='number'>£{this.displayRemoveVat()}</span></td>
            </tr>
            <tr>
              <td>Gross</td>
              <td><span className='number'>£{this.displayAddGross()}</span></td>
              <td><span className='number'>£{this.displayRemoveGross()}</span></td>
            </tr>
          </tbody>
        </table>
        {/* <h2>Add VAT</h2>
        <p>Net: <span className='number'>{this.displayAddNet()}</span></p>
        <p>VAT: <span className='number'>{this.displayAddVat()}</span></p>
        <p>Gross: <span className='number'>{this.displayAddGross()}</span></p>
        <h2>Remove VAT</h2>
        <p>Net: <span className='number'>{this.displayRemoveNet()}</span></p>
        <p>VAT: <span className='number'>{this.displayRemoveVat()}</span></p>
        <p>Gross: <span className='number'>{this.displayRemoveGross()}</span></p> */}
      </div>
    )
  }
}